<template>
  <div class="animate-spin rounded-full" :style="loaderStyle"></div>
</template>

<script setup lang="ts">
type Props = {
  size?: number
  color?: string
  borderWidth?: number
}

const props = withDefaults(defineProps<Props>(), {
  size: 48,
  color: '#3957D3',
  borderWidth: 2,
})

const loaderStyle = computed(() => ({
  width: `${props.size}px`,
  height: `${props.size}px`,
  border: `${props.borderWidth}px solid #E7E7E8`,
  borderTop: `${props.borderWidth}px solid ${props.color}`,
}))
</script>

<style scoped></style>
